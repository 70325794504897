(function($) {

  $(document).ready(function() {

    // Show and hide the navigation menu
    $('.js-navigation-toggle').click(function() {
      $(this).closest('.c-navigation').toggleClass('is-open');
    });

    // Toggle Modal
    $('.js-modal-toggle').click(function() {
      $('.c-modal').toggleClass('is-open');
    });


  });

})(jQuery);
